<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 10:08:58
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-23 15:28:23
-->
<template>
  <div>
    <!-- 顶部 -->
    <div class="head">
      <div class="head-logo" :style="{height: $store.getters.getIsPhone?'calc(100vh - 170px)':'calc(-70px + 100vh - 15vw)'}">
        <div style="width: 100%; text-align: center">
          <img :style="{maxWidth:$store.getters.getIsPhone?'500px':'100%'}" src="../assets/index/s1_tit.png" alt="" />
          <img >
        </div>
        <!-- <div class="down-btn" @click="down">
          <img src="../assets/index/andiron.png" alt="" />
          Android下载
        </div> -->
      </div>
    </div>
    <!-- 中部 -->
    <div class="middle1" :style="{height:$store.getters.getIsPhone?'1055px':'100vh'}">
      <img  v-if="$store.getters.getIsPhone" style="max-width: 1400px;" src="../assets/index/s2_imgbg.png" alt="" />
      <img  v-else style="width: 100%;" src="../assets/index/s2_img1.png" alt="" />
    </div>
    <div class="middle2">
      <img  v-if="$store.getters.getIsPhone" style="max-width: 1400px;" src="../assets/index/s3_imgbg.png" alt="" />
      <img  v-else style="width: 100%;" src="../assets/index/s3_img1.png" alt="" />
    </div>
    <div class="middle3">
      <img  v-if="$store.getters.getIsPhone" style="max-width: 1400px;" src="../assets/index/s4_imgbg.png" alt="" />
      <img  v-else style="width: 100%;" src="../assets/index/s4_img1.png" alt="" />
    </div>
    <!-- 底部下载 -->
    <!-- <div class="foot">
      <div class="foot-title">根据您的手机型号，点击下载哦</div>
      <div class="foot-card">
        <div class="cards" @click="down">
          <div class="card-icon">
            <img src="../assets/index/android.png" alt="" />
            <p>Android下载</p>
          </div>
        </div>
        <div class="cards">
          <div class="card-code">
            <img src="../assets/index/s5_scan_android.png" alt="" />
            <p>Android扫码下载</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="right-icon">
      <div class="icons" @mouseenter="handleEnter('wb')" @mouseleave="handleLeave('wb')" @click="goweb">
        <img v-show="!wbFlag" src="../assets/unwb.png" alt="" />
        <img v-show="wbFlag" src="../assets/news/wb.png" alt="" />
      </div>
      <div class="icons" @mouseenter="handleEnter('wx')" @mouseleave="handleLeave('wx')">
        <img src="../assets/unwx.png" alt="" />
        <img class="icons-code" v-show="wxFlag" src="../assets/ico-saomiao@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      wbFlag: false,
      wxFlag: false,
    }
  },
  methods: {
    down() {
      window.location.href = "http://www.chickpay.vip/res/app/AppBuilder/apk/4.4.3/chickpay-4.4.3_0809release.apk"
    },
    handleEnter(type) {
      if (type === "wb") {
        this.wbFlag = true
      } else if (type === "wx") {
        this.wxFlag = true
      }
    },
    handleLeave(type) {
      if (type === "wb") {
        this.wbFlag = false
      } else if (type === "wx") {
        this.wxFlag = false
      }
    },
    goweb() {
      window.open("http://weibo.com/32ka?topnav=1&wvr=6&topsug=1")
    },
  },
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  background: url(../assets/index/s1_bg.png) no-repeat center bottom #00b0ec;
  background-size: 100%;
  .head-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    padding-top: 70px;
    flex-wrap: wrap;
    .down-btn {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 40px;
      border-radius: 50px;
      border: 2px solid #fff;
      font-size: 23px;
      margin-top: 45px;
      cursor: pointer;
      img {
        width: 22px;
        margin-right: 16px;
      }
    }
  }
}
.middle1 {
  width: 100%;
  background-color: #fbd92a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.middle2 {
  width: 100%;
  height: 1080px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.middle3 {
  width: 100%;
  height: 1080px;
  display: flex;
  background-color: #00b1ed;
  align-items: center;
  justify-content: center;
}
.foot {
  width: 100%;
  margin-top: 100px;
  .foot-title {
    width: 100%;
    font-size: 46px;
    text-align: center;
    color: #000;
  }
  .foot-card {
    width: 100%;
    display: flex;
    margin: 40px 0;
    justify-content: center;
    .cards {
      width: 245px;
      height: 245px;
      margin-top: 40px;
      border-radius: 20px;
      border: 1px solid #e3e4e4;
      margin: 15px;
      transition: all 0.25s;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-icon {
        text-align: center;
        font-size: 16px;
        color: #979797;
        img {
          width: 55px;
        }
      }
      .card-code {
        text-align: center;
        font-size: 16px;
        color: #979797;
        img {
          width: 150px;
          margin-bottom: 8px;
        }
      }
    }
    .cards:hover {
      box-shadow: 0 20px 20px 3px #00000030;
    }
  }
}
.right-icon {
  position: fixed;
  right: 50px;
  bottom: 200px;
  background-color: #fff;
  border-radius: 10px;
  .icons {
    width: 51px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 100%;
    }
    .icons-code {
      position: absolute;
      width: 200px;
      left: -100px;
    }
  }
  .icons:last-child {
    border-top: 1px solid #ccc;
  }
}
</style>
